import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Inject, Injectable} from "@angular/core";

import {catchError, delay, forkJoin, map, Observable, of, retryWhen, switchMap, throwError} from "rxjs";
import {differenceInMinutes, set} from "date-fns";


import {CalendarsService} from "./calendars.service";
import {CALENDARS_CONFIGURATION} from "../../di/calendars-registration.model";
import {
  CalendarAvailability,
  CalendarContact,
  CalendarEvent,
  CalendarGroup,
  CalendarMeInfo,
  CalendarSchedule,
  CalendarsConfiguration,
  CalendarUser
} from "../../models";
import { findWindowsFromIana, WINDOWS_TO_IANA_MAP } from "./windows-iana-map";

@Injectable({
  providedIn: "root"
})
export class O365CalendarService extends CalendarsService {
  profileUrl = '/assets/images/profile.jpg';
  GRAPH_API_URL = 'https://graph.microsoft.com/v1.0';


  statusMap = [
    {
      key: 'reservationPopup.busy',
      value: 'busy'
    },
    {
      key: 'reservationPopup.free',
      value: 'free'
    },
    {
      key: 'reservationPopup.tentative',
      value: 'tentative'
    },
    {
      key: 'reservationPopup.workingElsewhere',
      value: 'workingElsewhere'
    },
    {
      key: 'reservationPopup.away',
      value: 'oof'
    }
  ];

  constructor(
    @Inject(CALENDARS_CONFIGURATION) private configuration: CalendarsConfiguration,
    private httpClient: HttpClient
  ) {
    super('o365');
  }



  private getURL(postfix: string) {
    return this.GRAPH_API_URL + postfix;
  }



  getMeInfo(): Observable<CalendarMeInfo> {
    const url = this.GRAPH_API_URL + '/me';
    return this.httpClient.get<any>(url).pipe(
      map(obj =>
        (({
            businessPhones,
            givenName,
            displayName,
            surname,
            id,
            jobTitle,
            mobilePhone,
            officeLocation
          }) => ({
          businessPhones,
          givenName,
          displayName,
          surname,
          id,
          jobTitle,
          mobilePhone,
          officeLocation
        }))(obj)
      )
    );
  }

  getImageUrl(email: string): Observable<string> {
    const url =  email === 'me' ? this.GRAPH_API_URL + `/${email}/photos/240x240/$value` : this.GRAPH_API_URL + `/users/${email}/photos/240x240/$value`;
    return this.httpClient.get(url, {responseType: 'blob'}).pipe(
      map(data => {
          try {
            return window.URL.createObjectURL(data);
          } catch {
            return this.profileUrl;
          }
        }
      ),
      catchError(() => of(this.profileUrl)
      )
    );
  }

  findContact(input: string): Observable<CalendarContact[]> {
    if (input === '') return of([]);
    const url = this.GRAPH_API_URL + `/users?$filter=startswith(mail,'${input}') or startsWith(surname,'${input}')  or startsWith(displayName,'${input}')  or startsWith(userPrincipalName,'${input}')`;
    return this.httpClient.get<{ value: CalendarContact[] }>(url).pipe(map(x => x.value));
  }



  findContactGroup(input: string): Observable<CalendarGroup[]> {
    if (input === '') return of([]);
    const groupUrls = this.GRAPH_API_URL + `/groups?$filter=startswith(displayName,'${input}')`;
    return this.httpClient.get<{ value: CalendarGroup[] }>(groupUrls).pipe(map(x => x.value));
  }


  getContactsFromGroup(id: string): Observable<CalendarContact[]> {
    const groupUrls = this.GRAPH_API_URL + `/groups/${id}/members`;
    return this.httpClient.get<{ value: CalendarContact[] }>(groupUrls).pipe(map(x => x.value));
  }


  deleteEvent(id: string): Observable<boolean> {
    const url = this.GRAPH_API_URL + '/me/events/' + id;
    return this.httpClient.delete(url, {
      observe: "response"
    }).pipe(
      map(value => value.status == 204),
      retryWhen(errors => {
        return errors.pipe(
          switchMap((response: HttpErrorResponse) => {
            if (response.status == 429) {
              return of(response).pipe(delay(parseInt(response.headers.get("retry-after") ?? "1") * 1000));
            }
            return throwError(()=>response);
          })
        );
      })
    );
  }

  finishEvent(eventId: string): Observable<{ id: string; start: Date; end: Date }> {

    const timezone = findWindowsFromIana(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const url = this.GRAPH_API_URL + '/me/calendar/events/' + eventId;
    return this.httpClient.patch<any>(url, {
      end: {
        dateTime: set(new Date(), {
          seconds: 0,
          milliseconds: 0
        }).toLocaleString('sv').replace(' ', 'T'),
        timeZone: timezone
      }
    }).pipe(
      map(bodyValue => ({
        id: bodyValue.id,
        start: bodyValue.start.dateTime,
        end: bodyValue.end.dateTime
      })),
      retryWhen(errors => {
        return errors.pipe(
          switchMap((response: HttpErrorResponse) => {
            if (response.status == 429) {
              return of(response).pipe(delay(parseInt(response.headers.get("retry-after") ?? "1") * 1000));
            }
            return throwError(()=> response);
          })
        );
      })
    );
  }

  patchEvent(
    visibility: "public" | "private",
    status: { key: string; value: string }, eventId: string, subject: string, text: string, start: Date, end: Date, roomName: string, roomEmail: string, attendees: { address: string; name: string; type: "required" | "optional" }[], onlineMeeting: { onlineMeetingProvider: string } | null = null): Observable<any> {


    const timezone = findWindowsFromIana(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const url = this.GRAPH_API_URL + '/me/calendar/events/' + eventId;
    return this.httpClient.patch<any>(url, {
      showAs: status.value,
      subject: subject,
      body: {
        contentType: 'HTML',
        content: text
      },
      start: {
        dateTime: set(start, {
          seconds: 0,
          milliseconds: 0
        }).toLocaleString('sv').replace(' ', 'T'),
        timeZone: timezone
      },
      end: {
        dateTime: set(end, {
          seconds: 0,
          milliseconds: 0
        }).toLocaleString('sv').replace(' ', 'T'),
        timeZone: timezone
      },
      location: {
        displayName: roomName,
        locationUri: roomEmail
      },
      isOnlineMeeting: onlineMeeting != null,
      onlineMeetingProvider: onlineMeeting != null ? onlineMeeting.onlineMeetingProvider : undefined,
      attendees: attendees.map(attendee => ({
        emailAddress: {
          address: attendee.address,
          name: attendee.name
        },
        type: attendee.type
      }))
    }).pipe(map(bodyValue => ({
        id: bodyValue.id,
        response: bodyValue.attendees[0].status.response
      })),
      retryWhen(errors => {
        return errors.pipe(
          switchMap((response: HttpErrorResponse) => {
            if (response.status == 429) {
              return of(response).pipe(delay(parseInt(response.headers.get("retry-after") ?? "1") * 1000));
            }
            return throwError(()=>response);
          })
        );
      })
    );
  }

  createEvent(
    visibility: "public" | "private",
    status: { key: string; value: string }, subject: string, text: string, start: Date, end: Date, roomName: string, roomEmail: string, attendees: { address: string; name: string; type: "required" | "optional" }[], onlineMeeting: { onlineMeetingProvider: string } | null = null, webexMeeting: { body: any; url: string; code: string; password: string } | null = null): Observable<{ id: string; response: string; iCalId: string }> {
    if (webexMeeting !== null) {
      const body = webexMeeting.body
        .replaceAll("{meeting-url}", "<a href='" + webexMeeting.url + "' target='_blank'>" + webexMeeting.url + "</a>")
        .replaceAll("{meeting-code}", webexMeeting.code)
        .replaceAll("{meeting-password}", webexMeeting.password);

      text = text + "<br/>" + body;
    }

    const timezone = findWindowsFromIana(Intl.DateTimeFormat().resolvedOptions().timeZone);


    const url = this.GRAPH_API_URL + '/me/calendar/events';
    return this.httpClient.post<any>(url, {
      showAs: status.value,
      subject: subject,
      body: {
        contentType: 'HTML',
        content: text
      },
      start: {
        dateTime: set(start, {
          seconds: 0,
          milliseconds: 0
        }).toLocaleString('sv').replace(' ', 'T'),
        timeZone: timezone
      },
      end: {
        dateTime: set(end, {
          seconds: 0,
          milliseconds: 0
        }).toLocaleString('sv').replace(' ', 'T'),
        timeZone: timezone
      },
      location: {
        displayName: roomName,
        locationUri: roomEmail
      },
      isOnlineMeeting: onlineMeeting != null,
      onlineMeetingProvider: onlineMeeting != null ? onlineMeeting.onlineMeetingProvider : undefined,
      attendees: attendees.map(attendee => ({
        emailAddress: {
          address: attendee.address,
          name: attendee.name
        },
        type: attendee.type
      }))
    }).pipe(
      map(bodyValue => ({
        id: bodyValue.id,
        response: bodyValue.attendees[0].status.response,
        iCalId: bodyValue.iCalUId
      })),
      retryWhen(errors => {
        return errors.pipe(
          switchMap((response: HttpErrorResponse) => {
            if (response.status == 429) {
              return of(response).pipe(delay(parseInt(response.headers.get("retry-after") ?? "1") * 1000));
            }
            return throwError(()=>response);
          })
        );
      })
    );
  }

  getCollegueInfo(email: string): Observable<CalendarUser> {
    const url = this.getURL(`/users/${email}`);
    return this.httpClient.get<any>(url, {
      headers: {
        Prefer: 'outlook.timezone="UTC"'
      }
    }).pipe(map(x => ({...x, base64Image: null})));
  }

  getScheduleForEmails(emails: string[], fromDate: Date, toDate: Date): Observable<CalendarSchedule[]> {
    const uniqueMails = emails.filter(this.onlyUnique);
    const chunks = this.getChunks(uniqueMails, 100);
    const fromDateString = set(fromDate, {seconds: 0, milliseconds: 0}).toISOString();
    const toDateString = set(toDate, {seconds: 0, milliseconds: 0}).toISOString();

    const filteredRequests = chunks.map(chunk  =>
     this.httpClient.post<any>(this.GRAPH_API_URL + '/me/calendar/getSchedule', {
       schedules: chunk,
       startTime: {
         dateTime: fromDateString,
         timeZone: 'UTC'
       },
       endTime: {
         dateTime: toDateString,
         timeZone: 'UTC'
       },
       availabilityViewInterval: 15
     }, {
       headers: {
         Prefer: 'outlook.timezone="UTC"'
       }
     }).pipe(
       map(x=> x.value),
       retryWhen(errors => {
         return errors.pipe(
           switchMap((response: HttpErrorResponse) => {
             if (response.status == 429) {
               return of(response).pipe(delay(parseInt(response.headers.get("retry-after") ?? "1") * 1000));
             }
            return throwError(()=>response);
           })
         );
       })
     )
    );

    if (filteredRequests.length == 0) {
      return of([]);
    }

    return forkJoin(filteredRequests).pipe(
      map((responseses: any[][]) => {
        const allItems = responseses.reduce((acc, item) => [...acc, ...item], []);

        return emails.map(email => {
          const x = allItems.find(x => x.scheduleId == email);
          if (x.error != null) {
            const data: CalendarSchedule = {
              email: email,
              availabilityView: new Array(Math.floor(differenceInMinutes(toDate, fromDate) / 15) + 1).map(_ => CalendarAvailability.limited).join(''),
              workingHours: x.workingHours,
              serviceError: x.error?.message != null ? x.error.message : x.error?.responsiveService,
              scheduleItems: []
            };
            return data;
          } else {
            const data: CalendarSchedule = {
              email: email,
              workingHours: x.workingHours,
              availabilityView: x.availabilityView,
              serviceError: null,
              scheduleItems: x.scheduleItems.map(sItem => ({
                subject: sItem.subject,
                location: sItem.location,
                availability: sItem.status == "free" ? CalendarAvailability.free : sItem.status == "tentative" ? CalendarAvailability.tentative : CalendarAvailability.reserved,
                start: new Date(sItem.start.dateTime + "Z"),
                end: new Date(sItem.end.dateTime + "Z"),
              }))
            };
            return data;
          }
        });
      })
    );
  }

  getIdentifierFromContact(contact: CalendarContact) {
    return contact.userPrincipalName ?? contact.mail;
  }

  availableMeetingProviders(): Observable<string[]> {
    const url = this.getURL('/me/calendar');
    return this.httpClient.get(url).pipe(
      map((r: any) => r.allowedOnlineMeetingProviders)
    );
  }

  getCalendarEvents(from: Date, to: Date, identifier: string, settings?: any): Observable<{
    events: CalendarEvent[];
    nextLink: string
  }> {
    let url: string;
    if (settings?.nextLink == null) {
      url = identifier == 'me' ? `https://graph.microsoft.com/v1.0/me/calendarView?startDateTime=${from.toISOString()}&endDateTime=${to.toISOString()}&top=30&$skip=0`
       :`https://graph.microsoft.com/v1.0/users/${identifier}/calendarView?startDateTime=${from.toISOString()}&endDateTime=${to.toISOString()}&top=30&$skip=0`;
    } else {
      url = settings.nextLink;
    }


    return this.httpClient.get(url, {
      headers: {
        Prefer: 'outlook.timezone="UTC"'
      }
    }).pipe(
      map((resp: any) => {
        if (resp.error?.code === 'ApplicationThrottled') {
          throw new Error('Microsoft doesnt work as usually - retry');
        }

        return {
          ...resp,
          value: resp.value.map(
            (x: any) => ({
              ...x,
              visibility: 'public',
              bodyPreview: x.bodyPreview,
              bodyHtml: x.body.content,
              status: this.statusMap.find(c => c.value === (x as any).showAs) ?? {
                key: 'reservationPopup.busy',
                value: 'busy'
              }
            }))
        };
      }),

      map((x: any) => ({events: x.value, nextLink: x['@odata.nextLink']})),
      map((x: any) => {
        return {
          ...x,
          events: x.events
        };
      }),
      map(x =>
        ({
          ...x, events: x.events.map((event: any) => {
              const data: CalendarEvent = {
                isOrganizer: event.isOrganizer,
                visibility: 'public',
                status: event.status,
                isAllDay: event.isAllDay,
                attendees: event.attendees,
                end: event.end,
                start: event.start,
                organizer: event.organizer,
                subject: event.subject,
                locations: event.locations ? [event.location, ...event.locations] : [event.location],
                id: event.id,
                iCalUId: event.iCalUId,
                type: event.type,
                seriesMasterId: event.seriesMasterId,
                onlineMeetingProvider: event.onlineMeetingProvider,
                bodyPreview: event.bodyPreview,
                bodyHtml: event.body.content
              };
              return data;
            }
          )
        })
      ),
      retryWhen(errors => {
        return errors.pipe(
          switchMap((response: HttpErrorResponse) => {
            if (response.status == 429) {
              return of(response).pipe(delay(parseInt(response.headers.get("retry-after")?? "1") * 1000));
            }
            return throwError(()=>response);
          })
        );
      })
    );
  }
}

